import React from 'react'
import PropTypes from 'prop-types'


class StrategyTile extends React.Component {
  render() {
    return (
      <div className="strategy-tile">
        <div className="logo" style={{backgroundImage:`url(${this.props.logoFile})`}}>
        </div>
        <div className={`info ${this.props.hideInfo? 'hidden' : ''}`}>
          <h2>{this.props.network}</h2>
          <p>{this.props.strategies}</p> 
        </div>
      </div>
    );
  }

}

StrategyTile.propTypes = {
  logoFile: PropTypes.string.isRequired,
  hideInfo: PropTypes.bool,
  network: PropTypes.string,
  strategies: PropTypes.string,
  url: PropTypes.string
}

export default StrategyTile
