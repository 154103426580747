import React from 'react'
import { goToAnchor } from 'react-scrollable-anchor'
import ScrollableAnchor from 'react-scrollable-anchor'

import Layout from '../components/layout'
import StrategyTile from '../components/StrategyTile'
import livepeerLogo from "../images/livepeer.png"
import compoundLogo from "../images/compound.png"
import steemLogo from "../images/steem.png"
import decredLogo from "../images/decred.png"
import nucypherLogo from "../images/nucypher.png"

class StrategiesPage extends React.Component {

  scrollTo(target) {
    if (target !== undefined) {
      console.log('target:', target)
      goToAnchor(target)
    }
  }

  render() {
    return (
      <Layout>
        <div id="strategies">
          <h1>Grassfed Strategies</h1>

          <div id="strategies-list">
            <ul>
              <li onClick={() => {this.scrollTo('decred')}}>
                <StrategyTile logoFile={decredLogo} url="http://decred.org" network="Decred" strategies="Staking" />
              </li>
              <li onClick={() => {this.scrollTo('livepeer')}}>
                <StrategyTile logoFile={livepeerLogo} url="http://livepeer.org" network="Livepeer" strategies="Software Mining, Transcoding" />
              </li>
              <li onClick={() => {this.scrollTo('compound')}}>
                <StrategyTile logoFile={compoundLogo} url="http://compound.finance" network="Compound Finance" strategies="Lending" />
              </li>
              <li onClick={() => {this.scrollTo('nucypher')}}>
                <StrategyTile logoFile={nucypherLogo} url="http://nucypher.com" network="NuCypher" strategies="Re-encryption Services" />
              </li>
              <li onClick={() => {this.scrollTo('steem')}}>
                <StrategyTile logoFile={steemLogo} url="http://steemit.com" network="Steem" strategies="Content Curation" />
              </li>
            </ul>
          </div>

          <section>
            <ScrollableAnchor id={'decred'}>
              <h1>Decred</h1>
            </ScrollableAnchor>

            <p><a href="http://decred.org/">Decred</a> was founded in 2016 with the mission to build best-in-class
            blockchain governance. The Decred network features a system of checks and balances between users, miners,
            and developers. It uses a hybrid Proof-of-Work (PoW) and Proof-of-Stake (PoS) voting mechanism to
            secure the network and achieve consensus. Decred also allows users and stakeholders to vote on proposals to
            improve the platform through <a href="https://proposals.decred.org/">Politeia (Pi)</a>, a blockchain-anchored public proposal system. On Politeia,
            users can propose, discuss, collaborate on, and fund new projects, initiatives, and consensus changes.</p>

            <h2>Strategy: Voting Service Provision</h2>
            <p>
              Grassfed Network operates a <a href="https://docs.decred.org/faq/proof-of-stake/voting-service-providers/">Voting Service Provider (VSP)</a> on the Decred network. DCR stakeholders
              that do not have the resources to run a full node or keep their Decred wallet unlocked in perpetuity have the
              option to authorize a VSP to vote on behalf of their tickets, and thus to participate in the
              PoS consensus of the protocol without taking on devops responsibilities. These parties receive rewards
              from the protocol for contributing to the security of the network. As a reward for its
              network participation, the Grassfed VSP receives fees from the Decred network.
            </p>

            <h2>Decred Resources</h2>
            <ul>
              <li><a href="https://docs.decred.org/getting-started/beginner-guide/">Beginner’s Guide to Decred</a></li>
              <li><a href="https://docs.decred.org/">Decred Overview</a></li>
              <li><a href="https://medium.com/coinmonks/decred-staking-guide-2e569d0390ff">Decred Staking Guide</a> by <a href="https://staked.us/">Staked</a></li>
            </ul>
          </section>

          <section>
            <ScrollableAnchor id={'livepeer'}>
                <h1>Livepeer</h1>
            </ScrollableAnchor>

            <p>
              Livepeer aims to deliver a live video streaming network protocol that is fully decentralized, highly
              scalable, crypto-token incentivized, and serves as the live media layer in the decentralized development
              stack. In addition, Livepeer is meant to provide an economically efficient alternative to centralized
              broadcasting solutions for any existing broadcaster. The Livepeer Protocol incentivizes participants to
              contribute to the live video broadcast network in a game-theoretically sound way.
            </p>

            <h2>Strategy: Software Mining (MerkleMine)</h2>
            <p>
              The <a href="https://github.com/livepeer/merkle-mine">Livepeer MerkleMine</a> was a cryptoeconomic game used by the Livepeer network to distribute its token
              (<a href="https://coinmarketcap.com/currencies/livepeer">LPT</a>) to the public. During a limited time period, third-parties  could submit a cryptographic proof on
              behalf of a subset of Ethereum addresses to compel the distribution of a fixed amount of LPT to those
              addresses, while being incentivized to do so through an LPT reward.
            </p>

            <h2>Strategy: Transcoding</h2>
            <p>
              Grassfed Network operates a transcoder on the network for the Livepeer network. This transcoder earns daily
              LPT-denominated inflation rewards. As Livepeer builds traction with broadcasters, the transcoder is able to
              earn fees in ETH for the service of transcoding video.
            </p>

            <h2>Livepeer Resources</h2>
            <ul>
              <li><a href="https://twitter.com/jbrukh/status/1030946463893872640">Livepeer Token Economics</a></li>
              <li><a href="https://blog.coinfund.io/livepeer-cryptoeconomics-as-a-case-study-of-active-participation-in-decentralized-networks-19a932415e0e">Livepeer cryptoeconomics as a case study of active participation in decentralized networks</a></li>
              <li><a href="https://multicoin.capital/2018/11/09/new-models-for-token-distribution/">A new model for token distribution</a> by <a href="http://multicoin.capital">Multicoin Capital</a></li>
              <li><a href="https://medium.com/coinmonks/livepeer-staking-guide-de9fb52db671">Livepeer Staking Guide</a> by <a href="https://staked.us/">Staked</a></li>
            </ul>

          </section>



          <section>
            <ScrollableAnchor id={'compound'}>
              <h1>Compound Finance</h1>
            </ScrollableAnchor>
            <p>
              <a href="https://compound.finance/">Compound</a> is a money market protocol that allows users to lend and
            borrow cryptographic assets frictionlessly, without requiring any negotiation with a counterparty. Every
            lending market for an asset features dynamic interest rates that update in response to liquidity conditions in real-time,
            increasing in times of increased demand and decreasing when there is a larger pool of assets available to borrow.
          Lenders accrue interest with every Ethereum block.
            </p>

            <h2>Strategy: Cryptoasset Lending</h2>
            <p>Grassfed is a lender of cryptoassets on the Compound protocol.</p>
          </section>

          <section>
            <ScrollableAnchor id={'nucypher'}>
              <h1>NuCypher</h1>
            </ScrollableAnchor>

            <p>
              <a href="http://nucypher.com">NuCypher</a> is a data privacy layer for blockchain and decentralized
              applications. Specifically, the network uses proxy re-encryption to bring private data to public
              blockchains. Proxy re-encryption is a scalable form of public-key encryption that allows a proxy entity
              to transform encrypted data from one public key to another, without ever having access to the
              underlying plaintext data or any private keys. Using proxy re-encryption, NuCypher provides access
              control services that allow applications to ensure that their data remains private, confidential and
              encrypted while maintaining the ability to share that data with trusted parties. It can be used across
              a variety of use cases, including sharing encrypted files, end-to-end encrypted group chat, and
              patient-controlled electronic health records, among others.
            </p>

            <h2>Strategy: Re-encryption Services</h2>
            <p>Grassfed Network is planning to provide re-encryption services on the NuCypher network when it launches on
              mainnet in 2019.
            </p>
          </section>

          <section>
            <ScrollableAnchor id={'steem'}>
              <h1>Steem</h1>
            </ScrollableAnchor>

            <p>
              <a href="http://steemit.com">Steemit</a> is a social network and blogging platform that rewards content contributors and is built on top of the <a href="http://steem.io">Steem blockchain</a>.
            A contributor can post content, upvote or downvote posts, and comment on content. In return for posting work
            that is upvoted or for curating content by upvoting it, users earn cryptocurrency rewards in one of Steem’s
            native assets.
            </p>

            <h2>Strategy: Content Curation</h2>
            <p>Grassfed Network runs a content curation bot on the platform. In return for a payment in SBD or
              STEEM, our bot is able to leverage its large holdings in SP to upvote some selected content on Steemit.
              A content creator may want to use this bot in order to increase the visibility or popularity of his or
              her content and in turn make a return in Steemit platform rewards.
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default StrategiesPage
